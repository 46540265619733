import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        nextKey: Number,
    };

    static targets = ['address', 'template'];

    connect () {
        this.nextKey = this.nextKeyValue;
    }

    add () {
        let template = this.templateTarget.innerHTML;
        template = template.replaceAll('%key%', this.nextKey++);

        this.addressTarget.append(document.createRange().createContextualFragment(template))
    }

    remove (event) {
        this.addressTarget.querySelector(`[data-gsp-sponsors-key="${event.params.key}"]`).remove();
    }
}
